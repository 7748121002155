/* CSS */
.button-16 {
  background-color: #eaeaea;
  border: 1px solid #dadada;
  border-radius: 4px;
  color: #000000;
  cursor: pointer;
  font-family: arial, sans-serif;
  font-size: 12px;
  height: 50%;
  line-height: 30%;
  min-width: 60px;
  padding: 0 16px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: pre;
}

.button-16:hover {
  border-color: #dadce07e;
  box-shadow: rgba(0, 0, 0, 0.041) 0 1px 1px;
}

.button-16:focus {
  border-color: #0d9963;
  outline: none;
}

.Nav {
  background: #fff;
  height: 4rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  border-bottom: 0.1rem solid #15171c2e;
}

.NavIcon {
  width: 100%;
  margin-left: 2rem;
  margin-right: 2rem;
  font-size: 2rem;
  height: 100%;
  font-size: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.SidebarNav {
  background: #fff;
  width: 25vh;
  height: 100vh;
  display: flex;
  justify-content: center;
  box-shadow: 15px 19px 25px -18px rgba(0, 0, 0, 0.271);
  -webkit-box-shadow: 15px 19px 25px -18px rgba(0, 0, 0, 0.366);
  -moz-box-shadow: 15px 19px 25px -18px rgba(0, 0, 0, 0.331);
  position: fixed;
  top: 1;
  transition: 35000ms;
  z-index: 10;
}

.SidebarWrap {
  width: 100%;
}
