@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Sans:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic);

.titlestaff {
  font-family: "IBM Plex Sans";
  font-weight: 300;
  font-size: 200%;
  margin: 1% 5%;
}

.divstaff {
  overflow-x: hidden;
  overflow-y: auto;
  margin: 1%;
  text-align: center;
}

.boxstaff {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: auto;
  padding: 0;
}

.table2staff {
  font-family: "Montserrat", sans-serif;
  font-size: 75%;
  margin: auto;
  padding: 0px;
}

.cbustaff {
  font-family: "IBM Plex Sans";
  font-weight: 300;
  font-size: 100%;
  margin-left: 5%;
}

.txtstaff {
  display: flex;
  flex-direction: row;
  align-content: flex-end;
  align-items: flex-end;
}

.rowStaff {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "IBM Plex Sans";
  font-weight: 300;
  font-size: 100%;
  margin-left: 5%;
  width: 90%;
}

.montostaff {
  text-align: end;
}

.inputRetestaff {
  width: 12%;
  font-size: small;
}

/* CSS */
.button-filter {
  appearance: button;
  backface-visibility: hidden;
  background-color: #0d9963;
  border-radius: 6px;
  border-width: 0;
  box-shadow: rgba(50, 50, 93, 0.1) 0 0 0 1px inset,
    rgba(50, 50, 93, 0.1) 0 2px 5px 0, rgba(0, 0, 0, 0.07) 0 1px 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-size: 100%;
  outline: none;
  overflow: hidden;
  padding: 0 25px;
  position: relative;
  text-align: center;
  text-transform: none;
  transform: translateZ(0);
  transition: all 0.2s, box-shadow 0.08s ease-in;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
}

.button-filter:disabled {
  cursor: default;
}

.button-filter:focus {
  box-shadow: rgba(50, 50, 93, 0.1) 0 0 0 1px inset,
    rgba(50, 50, 93, 0.2) 0 6px 15px 0, rgba(0, 0, 0, 0.1) 0 2px 2px 0,
    rgba(50, 151, 211, 0.3) 0 0 0 4px;
}

.analisis {
  text-decoration: underline overline;
  background-color: #fff;
  color: #000;
}

.p-gray-small {
  font-size: 80%;
  color: #666;
  margin: 0px;
  padding: 0px;
  margin-left: 1%;
}