.body-r {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container-r {
  max-width: 900px;
  margin: 30px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.h3-r {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.rowform-r {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.label-r {
  width: 100%;
  font-weight: bold;
  margin-bottom: 5px;
  color: #555;
}

.col-r {
  flex: 1;
  padding: 10px;
}

.form-control-plaintext-r {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  min-height: 40px;
  display: flex;
  align-items: center;
  color: #333;
}

@media (min-width: 768px) {
  .col-r {
    width: 50%;
  }

  .rowform-r .label-r {
    width: 50%;
  }
}
