@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Sans:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic);

.title {
  font-family: "IBM Plex Sans";
  font-weight: 300;
  font-size: 200%;
  margin: 1% 10%;
}

.div {
  margin: auto;
  width: 80%;
}

.box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: auto;
  padding: 0;
  width: 100%;
  height: 100%;
}

.table2 {
  font-family: "Montserrat", sans-serif;
  font-size: 80%;
  margin: auto;
  padding: 0px;
  width: 100%;
  height: 100%;
}

.cbu {
  font-family: "IBM Plex Sans";
  font-weight: 300;
  font-size: 100%;
  margin-left: 10%;
}

.monto {
  text-align: center;
}

.inputRete {
  width: 12%;
  font-size: small;
}
