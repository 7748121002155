@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Sans:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic);
.divform {
  font-family: 'Montserrat', sans-serif;
  margin-left: 20%;
  font-size: 70%;
  height: 100%;
  width: 50%;
}

.divform label {
  font-weight: bold;
}

.titleform {
  font-family: 'IBM Plex Sans';
  font-weight: 300;
  font-size: 200%;
  margin: auto;
}

.rowform {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

/* CSS */
.button-9 {
  appearance: button;
  backface-visibility: hidden;
  background-color: #0d9963;
  border-radius: 6px;
  border-width: 0;
  box-shadow: rgba(50, 50, 93, 0.1) 0 0 0 1px inset,
    rgba(50, 50, 93, 0.1) 0 2px 5px 0, rgba(0, 0, 0, 0.07) 0 1px 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-size: 120%;
  height: 3rem;
  outline: none;
  overflow: hidden;
  padding: 0 25px;
  position: relative;
  text-align: center;
  text-transform: none;
  transform: translateZ(0);
  transition: all 0.2s, box-shadow 0.08s ease-in;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 30%;
}

.button-9:disabled {
  cursor: default;
}

.button-9:focus {
  box-shadow: rgba(50, 50, 93, 0.1) 0 0 0 1px inset,
    rgba(50, 50, 93, 0.2) 0 6px 15px 0, rgba(0, 0, 0, 0.1) 0 2px 2px 0,
    rgba(50, 151, 211, 0.3) 0 0 0 4px;
}

.act {
  background-color: #0d996376;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

@media screen and (max-width: 600px) {
  .divform {
    width: 90%;
    height: 100%;
    margin: auto;
    font-size: 120%;
  }

  .button-9 {
    width: 100%;
    height: 100%;
    padding: 5%;
    margin: 5% 0%;
    font-size: 90%;
  }
  .selectMobile {
    font-size: 22px;
  }

  .rowform {
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .rowform input {
    padding: 4%;
    margin: auto;
    font-size: 20px;
  }

  .rowform label {
    font-size: 18px;
  }
}
