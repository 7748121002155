.sidebarlink {
  display: flex;
  color: #000000;
  justify-content:flex-start;
  align-items:center;
  padding: 5%;
  list-style: none;
  height: 15%;
  text-decoration: none;
  font-size: 100%;
  border-bottom: 0.1rem solid #15171c2e;
}

.sidebarlink:hover {
  color: #000000;
  background: #2528316f;
  border-left: 4px solid #0D9963;
  cursor: pointer;
}
.SidebarLabel{
  margin:16%;
}



