.background {
  background-color: #0d9963;
  height: 100%;
}

.master {
  min-height: 89.9vh;
}

.color-font {
  display: flex;
  flex-direction: column;
  padding: 8%;
  color: #fff;
  background-color: #666666fc;
  width: 60%;
  margin: auto;
  box-shadow: 8px 10px 36px -5px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 8px 10px 36px -5px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 8px 10px 36px -5px rgba(0, 0, 0, 0.6);
}

.button {
  background-color: #f8f9fa;
  border-radius: 6px;
  border-width: 0;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 20%;
  line-height: 1.15;
  outline: none;
  overflow: hidden;
  text-align: center;
  align-items: center;
  text-transform: none;
  width: 20%;
}

/* CSS */
.button-18 {
  background-color: #3b71ca;
  border-radius: 6px;
  border-width: 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-size: 100%;
  height: 40px;
  line-height: 1.15;
  outline: none;
  overflow: hidden;
  padding: 0 10%;
  position: relative;
  text-align: center;
  text-transform: none;
  transform: translateZ(0);
  transition: all 0.2s, box-shadow 0.08s ease-in;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
}

.button-18:disabled {
  cursor: default;
}

.button-18:focus {
  box-shadow: rgba(50, 50, 93, 0.1) 0 0 0 1px inset,
    rgba(50, 50, 93, 0.2) 0 6px 15px 0, rgba(0, 0, 0, 0.1) 0 2px 2px 0,
    rgba(50, 151, 211, 0.3) 0 0 0 4px;
}

@media (max-width: 600px) {
  .titleMobile {
    font-size: 25px;
    margin-bottom: 50px;
  }
  .master {
    min-height: 89.9vh;
  }
  .color-font {
    width: 100%;
    text-align: center;
    font-size: 22px;
  }
  .color-font input {
    width: 100%;
    padding: 3%;
    margin: 5% 0%;
    font-size: 22px;
  }
  .color-font button {
    padding: 3%;
    margin: 5% 0%;
    font-size: 22px;
  }
  .button-18 {
    height: 60px;
  }
}
