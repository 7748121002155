@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Sans:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic);

.Titulo {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 50%;
  text-align: center;
  font-family: "IBM Plex Sans";
  font-weight: 300;
  font-size: 250%;
}

.material-icons {
  color: #0d9963;
  text-align: center;
}

.parent-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 50%;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.icon-container1 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 12rem;
  height: 100%;
  padding: 5%;
  box-shadow: 10px 9px 41px -6px rgba(0, 0, 0, 0.202);
  -webkit-box-shadow: 10px 9px 41px -6px rgba(0, 0, 0, 0.188);
  -moz-box-shadow: 10px 9px 41px -6px rgba(0, 0, 0, 0.181);
}

.icon-container1:hover,
.icon-container1:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.icon-container1:hover {
  transform: translateY(-1px);
}

.icon-container1:active {
  background-color: #f0f0f1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}

.p {
  color: #54595ed0;
  font-family: "IBM Plex Sans";
  font-weight: 200;
}

.a:link,
.a:visited,
.a:active {
  text-decoration: none;
  margin: 5%;
}

@media only screen and (max-width: 470px) and (max-height: 860px) {
  @import url(https://fonts.googleapis.com/css?family=IBM+Plex+Sans:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic);

  .Titulo {
    font-size: 20px;
    top: 20%;
    max-width: 70%;
  }

  .material-icons {
    font-size: 10px;
  }

  .parent-container {
    display: block;
    flex-direction: column;
    height: 70%;
    top: 62%;
  }

  .icon-container1 {
    width: 100%;
    height: 40%;
    margin: auto;
    padding: 0%;
    font-size: 15px;
  }

  .p {
    font-size: 5px;
    margin: 0px;
  }
}
